<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <submenu-account menu="api"></submenu-account>

    <div class="submenu-content">
      <h2 class="pb-4">Web Service API</h2>
      
      <p class="">
          <div>Ma Labs Web Service(MWS) offers a RESTful Web Service API.</div>
          <div>For more information, please refer to <a :href="documents[2]" download="Ma_Labs_Web_Service_FAQ-2021.pdf">Ma Labs Web Service(MWS) FAQ</a> (click to download)</div>
      </p>

      <div v-if="error">
        <div class="alert alert-danger alert-dismissible fade show" role="alert">
          {{error}}
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>
      <div v-if="message">
        <div class="alert alert-success alert-dismissible fade show" role="alert">
          {{message}}
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>

      <div v-if="is_customer">
        <div v-if="mws_status == false">
          <p>
            If you already enrolled in Ma Labs Data Feed Service and would like to open an account for the MWS program, please check the box below to begin the application. Our team will review your profile and email you the Web Service API account information within 3 business days after approval.
          </p>

          <form @submit.prevent="handleSubmit">
            <div  :class="{'alert alert-danger': mws_error}">
              <div v-if="mws_error" class="mb-2">This field is required</div>
              <div class="mb-4 form-check">
                <input type="checkbox" class="form-check-input" v-model="mws">
                
                <label class="form-check-label ps-2">I am in the Ma Labs Data Feed Service program and would like to open a Ma Labs Web Service Account.</label>
              </div>
            </div>
            <button type="submit" v-if="waiting == false" class="btn btn-primary">Submit</button>
            <button type="submit" v-if="waiting == true" class="btn btn-primary" disabled>
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Submit            
            </button>

            <p class="mt-5">
              <span class="badge bg-danger">Important Note</span>
  Enrollment of Ma Labs’ Data Feed Service is required to apply for Web Service API. Please refer to the Data Feed Service or contact your sales representative for more information.
            </p>
          </form>
        </div>
      </div>
      
    </div> 
      
  

  </div>
</template>

<script>
import submenuAccount from '../../components/nav/submenuAccount.vue'
import BreadcrumbPage from "../../components/BreadcrumbPage.vue";
import { useStore } from 'vuex'
import { ref } from 'vue'
import { getAuthAPI,getAPI } from '../../utils/axios-api'

export default {
  name: 'WebService',
  components: { submenuAccount, BreadcrumbPage },
  setup() {
    const waiting = ref(false)
    const message = ref("")
    const error = ref("")

    const store = useStore()
    const user = ref(store.getters.user)
    const is_customer = ref(store.getters.isCustomer)
    const user_name = ref(store.getters.userName)
    const custno = ref(store.getters.custno)
    const user_email = ref(store.getters.userEmail)

    const mws_status = ref(true)
    const mws = ref(false)
    const mws_error = ref(false)
    const documents = ref([])

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "My Account", link: ""})
    breadcrumb_links.value.push({text: "Web Service API", link: "WebService"})

    if (is_customer) {
      getAuthAPI.get('/product/customer-service/status/' + custno.value + '/'+ user_email.value)
      .then(response => {
        mws_status.value = response.data.mws
      })
      .catch(err => {
          error.value = err.message
      })
    }


    // get static datafeed and api documents
    getAPI.get('/content/pages/65/')
      .then(response => {
        let feed = response.data.body
        for(let index in feed){
          documents.value.push("/"+feed[index].value)
        }

      })
      .catch(err => {
          error.value = err.message
      })

    function validate() {
      if (mws.value == false) {
        mws_error.value = true
        return false
      } 
      return true
    }

    const handleSubmit = () => {
      waiting.value = true
      error.value = ""
      message.value = ""

      if (validate()) {
        let payload = {"mws": mws.value,
                      "custno": custno.value,
                      "email": user_email.value,
                      "company": user_name.value}
        
        getAuthAPI.post('/product/customer-service/mws/', payload)
        .then(response => {
          message.value = response.data.message
          waiting.value = false
          mws_status.value = true
        })
        .catch(err => {
            error.value = err.message
            waiting.value = false
        })
      }
      else {
        waiting.value = false
      }

    }

    return {
      waiting,
      message,
      error,
      is_customer,
      mws_status,
      mws,
      mws_error,
      handleSubmit,
      documents,
      breadcrumb_links
    }
  }
}
</script>


<style scoped>
.red {color: red;}

</style>